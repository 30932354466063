'use client'

import React, { useState, useTransition } from 'react'
import { useRouter, usePathname } from '@/navigation'
import 'yet-another-react-lightbox/styles.css'
import 'swiper/css'
import classNames from 'classnames'
import UkFlagIcon from '@/images/icons/uk.svg'
import EnFlagIcon from '@/images/icons/en.svg'
import styles from './HeaderMobileLanguageSwitche.module.scss'

type TProps = {
  locale: string
  className?: string
}

export default function HeaderLanguageSwitcher({ locale, className }: TProps) {
  const router = useRouter()
  const pathname = usePathname()

  const [_isPending, startTransition] = useTransition()

  const handleLanguageChange = (option: string) => {
    setIsOpen(false)
    startTransition(() => {
      router.replace(pathname, { locale: option })
    })
  }

  const [isOpen, setIsOpen] = useState<boolean>(false)

  const options = [
    {
      icon: UkFlagIcon,
      value: 'uk',
    },
    {
      icon: EnFlagIcon,
      value: 'en',
    }
  ]

  return (
    <div className={classNames('language-switcher col', className)}>
      <div className={classNames([styles.languageSwitcher__wrapper], {[styles.languageSwitcher__wrapper_active]: isOpen})}>
        <div
          className={classNames([styles.languageSwitcher__button])}
          onClick={() => setIsOpen(!isOpen)}
        >
          {React.createElement(options.find(x => x.value === locale)?.icon, {width: 32, height: 32})}
        </div>

        {isOpen && (
          <div className={classNames([styles.languageSwitcher__dropdown])}>
            <ul>
              {options.filter(x => x.value !== locale).map((x, index) => {
                return (
                  <li
                    key={index}
                    className={classNames([styles.languageSwitcher__dropdown__option])}
                    onClick={() => handleLanguageChange(x.value)}
                  >
                    {React.createElement(x.icon, {width: 32, height: 32})}
                  </li>
                )
              })}
            </ul>
          </div>
        )}
      </div>
    </div>
  )
}