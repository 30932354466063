'use client'

import React, { useState, useTransition } from 'react'
import { useRouter, usePathname } from '@/navigation'
import 'yet-another-react-lightbox/styles.css'
import 'swiper/css'
import classNames from 'classnames'

type TProps = {
  locale: string
  className?: string
}

export default function HeaderMobileLanguageSwitcher({ locale, className }: TProps) {
  const router = useRouter()
  const pathname = usePathname()
  const [language, setLanguage] = useState<string>(locale)

  const [_isPending, startTransition] = useTransition()

  const handleLanguageChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const locale = e.target.value
    setLanguage(locale)
    startTransition(() => {
      router.replace(pathname, { locale })
    })
  }

  return (
    <div className={classNames('language-switcher col', className)}>
      <select
        className='form-select'
        value={language}
        aria-label='Language'
        onChange={(e) => handleLanguageChange(e)}
      >
        <option value='uk'>UA</option>
        <option value='en'>EN</option>
      </select>
    </div>
  )
}