'use client'

import {useTranslations} from 'next-intl'
import React, { useState, ChangeEvent, useEffect, useRef } from 'react'
import classNames from 'classnames'
import debounce from 'lodash.debounce'
import { useRouter, useSearchParams } from 'next/navigation'
import "yet-another-react-lightbox/styles.css"
import 'swiper/css'

type TProps = {
  opened: boolean
  close: () => void
}

const HeaderSearchForm = ({opened, close}: TProps) => {
  const t = useTranslations()
  const router = useRouter()
  const oldQueryParams = useSearchParams()
  
  const pathname = '/products'
  
  const [searchText, setSearchText] = useState<string | null>(null)
  const inputRef = useRef<HTMLInputElement | null>(null);
  const newQueryParams = useRef(new URLSearchParams())

  const handleInputChange = (event: ChangeEvent<HTMLDivElement>) => {
    setSearchText((event.target as HTMLInputElement).value)
  }

  const handleInputOnKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      event.preventDefault()
      event.stopPropagation()
      setSearchText((event.target as HTMLInputElement).value)
      router.push(`${pathname}?${newQueryParams.current.toString()}`)
    }
  }

  const throttled = useRef(debounce((newValue: string | null) => {
    if (newValue) {
      newQueryParams.current.set('text', newValue)
      router.push(`${pathname}?${newQueryParams.current.toString()}`)
    } else if (newValue === '') {
      newQueryParams.current.delete('text')
      router.push(`${pathname}?${newQueryParams.current.toString()}`)
    }
  }, 500))

  useEffect(() => {
    if (opened && inputRef.current) {
      inputRef.current.focus()
      if (newQueryParams.current.has('text')) {
        inputRef.current.value = newQueryParams.current.get('text') as string;
      }
    }
  }, [opened])

  // Set default query params
  useEffect(() => {
    for (const [key, value] of oldQueryParams.entries()) {
      newQueryParams.current.set(key, value)
    }
    return () => {
      newQueryParams.current = new URLSearchParams()
    }
  }, [oldQueryParams])

  useEffect(() => throttled.current(searchText), [searchText])

  return (
    <div className={classNames('search-form', {'open': opened})}>
      <form action="/products" className="container-fluid">
        <input 
          ref={inputRef}
          type="text" 
          placeholder={t('menu.search-placeholder')}
          onChange={handleInputChange}
          onKeyDown={handleInputOnKeyDown}
        />
        <button
          type="button"
          className='search-btn'
          onClick={close}
        >
        </button>
      </form>
    </div>
  )
}

export default HeaderSearchForm