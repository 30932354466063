import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/node_modules/.pnpm/next-intl@3.21.1_next@14.2.15_@babel+core@7.25.8_@opentelemetry+api@1.9.0_react-dom@18.3.1_re_3jx2riymclxzs7yodf7ffhvyv4/node_modules/next-intl/dist/esm/shared/NextIntlClientProvider.js");
;
import(/* webpackMode: "eager" */ "/app/node_modules/.pnpm/next@14.2.15_@babel+core@7.25.8_@opentelemetry+api@1.9.0_react-dom@18.3.1_react@18.3.1__react@18.3.1_sass@1.80.3/node_modules/next/dist/client/image-component.js");
;
import(/* webpackMode: "eager" */ "/app/node_modules/.pnpm/next@14.2.15_@babel+core@7.25.8_@opentelemetry+api@1.9.0_react-dom@18.3.1_react@18.3.1__react@18.3.1_sass@1.80.3/node_modules/next/dist/client/link.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/components/shared/Header.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["FacebookPixelEvents"] */ "/app/src/components/smart/FacebookPixelEvents.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/providers/ClientProvider.tsx");
