"use client"

import { SessionProvider } from "next-auth/react"
import React from 'react'
import SettingsContextProvider from '@/context/settings-context'
import { IShoppingCart, ISetting } from '@/entities'
import { ShoppingCartContextProvider } from '@/context/shopping-cart-context'

export default function Provider({
  children, session, settings, initialShoppingCart
}: {
  children: React.ReactNode
  session: any
  settings: ISetting[],
  initialShoppingCart: IShoppingCart
}): React.ReactNode {
  return <SessionProvider session={session}>
    <SettingsContextProvider settings={settings}>
      <ShoppingCartContextProvider initialShoppingCart={initialShoppingCart}>
        {children}
      </ShoppingCartContextProvider>
    </SettingsContextProvider>
  </SessionProvider>
}
